<script>
import { Bar, mixins } from 'vue-chartjs'

const { reactiveProp } = mixins;
export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ["chartData", "options"],
  computed: {
    language() {
      return this.$store.state.settings.language;
    },
  },
  methods:{
    updateChart(){
      this.renderChart(this.chartData, this.options);
    }
  },
  watch: {
    language() {
      this.renderChart(this.chartData, this.options);
    },
    chartData() {
      this.renderChart(this.chartData, this.options);
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  }
}
</script>